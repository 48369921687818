(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/game-analyses-redux/assets/game-analyses-actions.js') >= 0) return;  svs.modules.push('/components/sportinfo/game-analyses-redux/assets/game-analyses-actions.js');
"use strict";


const {
  createAsyncThunk
} = RTK;
const {
  getDrawAnalyses
} = svs.content.shared.cms;
const {
  normalizeAnalyses
} = svs.components.sportinfo.gameAnalysesRedux.normalizeAnalyses;
const {
  normalizeDrawData,
  normalizeDrawDataFromAnalyseData
} = svs.components.sportinfo.gameAnalysesRedux.normalizeDrawData;
const {
  products
} = svs.utils;
const {
  jupiter,
  log
} = svs.core;
const logger = log.getLogger('game-analyses-redux:game-analyses-actions');
const fetchDrawAnalyses = createAsyncThunk('gameAnalyses/fetchDrawAnalyses', async _ref => {
  let {
    productId,
    drawNumber
  } = _ref;
  const analyses = await getDrawAnalyses({
    count: 20,
    id: drawNumber,
    product: productId,
    withLegacyFilter: false
  });
  const normalisedGameAnalyses = normalizeAnalyses(analyses.result);
  const normalizedDrawData = normalizeDrawDataFromAnalyseData(normalisedGameAnalyses);
  return {
    normalisedGameAnalyses,
    normalizedDrawData
  };
});
const fetchDrawData = createAsyncThunk('gameAnalyses/fetchDrawData', async _ref2 => {
  let {
    productId,
    drawNumber
  } = _ref2;
  const url = "/draw/1/".concat(products.getSpecificDrawName(productId), "/draws/").concat(drawNumber, "?include=draw.events.match.matchId,draw.events.match.participants.name,draw.events.match.participants.shortName");
  const response = await jupiter.get(url);
  let draw;
  if (response.error) {
    logger.info("Error fetching matchId and participantNames, error: ".concat(response.error));
  } else {
    draw = response.draw;
  }
  return normalizeDrawData(draw);
});
setGlobal('svs.components.sportinfo.gameAnalysesRedux.actions', {
  fetchDrawAnalyses,
  fetchDrawData
});

 })(window);