(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/game-analyses-redux/assets/game-analyses-hooks.js') >= 0) return;  svs.modules.push('/components/sportinfo/game-analyses-redux/assets/game-analyses-hooks.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  useEffect,
  useMemo
} = React;
const {
  useDispatch,
  useSelector
} = ReactRedux;
const {
  fetchDrawAnalyses,
  fetchDrawData
} = svs.components.sportinfo.gameAnalysesRedux.actions;
const {
  EventTypeId
} = svs.components.sportinfo.common.constants;
const {
  cdn2
} = svs.core.config.data.svsconfig;
const {
  convertToSigns
} = svs.components.sport.tipsenShared;
const {
  selectDrawAnalyses,
  selectAnalysesByDraw,
  selectAuthorProfiles,
  selectMatchAnalyses,
  selectParticipantNamesByMatchId
} = svs.components.sportinfo.gameAnalysesRedux.selectors;
const {
  PREDICTION_SROW
} = svs.components.sportinfo.gameAnalysesRedux.constants;
const FIVE_MINUTES_AS_MILLISECONDS = 1000 * 60 * 5;
const useFetchAnalysesByDraw = (drawNumber, productId) => {
  const dispatch = useDispatch();
  const lastFetch = useSelector(state => {
    var _state$gameAnalyses$l;
    return (_state$gameAnalyses$l = state.gameAnalyses.lastFetchByDraw["".concat(productId, "_").concat(drawNumber)]) !== null && _state$gameAnalyses$l !== void 0 ? _state$gameAnalyses$l : 0;
  });
  useEffect(() => {
    const diff = Date.now() - lastFetch;
    if (diff > FIVE_MINUTES_AS_MILLISECONDS) {
      dispatch(fetchDrawAnalyses({
        drawNumber,
        productId
      }));
    }
  }, [dispatch, drawNumber, productId, lastFetch]);
  useEffect(() => {
    const diff = Date.now() - lastFetch;
    if (diff > FIVE_MINUTES_AS_MILLISECONDS) {
      dispatch(fetchDrawData({
        drawNumber,
        productId
      }));
    }
  }, [dispatch, drawNumber, productId, lastFetch]);
};
const useAuthorProfilePicture = authorProfile => useMemo(() => {
  if (authorProfile) {
    const profileImage = authorProfile.images.find(image => image.typeName === 'PROFILE');
    if (profileImage) {
      return cdn2 + profileImage.url;
    }
  }
  return null;
}, [authorProfile]);
const useAnalysisOutcomes = matchAnalysis => {
  const outcomes = useMemo(() => {
    const predictions = matchAnalysis.gamePredictions.prediction ? [...matchAnalysis.gamePredictions.prediction] : [];
    if (predictions.length > 0) {
      const srowPredictionIndex = predictions.findIndex(prediction => prediction.system === PREDICTION_SROW);

      if (srowPredictionIndex !== -1) {
        const srowPrediction = predictions.splice(srowPredictionIndex, 1);
        predictions.unshift(srowPrediction[0]);
      }
    }
    return predictions[0].outcomes;
  }, [matchAnalysis.gamePredictions.prediction]);
  return outcomes;
};
const useDrawAnalyses = drawId => {
  const drawAnalyses = useSelector(selectDrawAnalyses);
  const drawAnalysesByDraw = useSelector(selectAnalysesByDraw);
  return useMemo(() => {
    var _drawAnalysesByDraw$d;
    return ((_drawAnalysesByDraw$d = drawAnalysesByDraw[drawId]) !== null && _drawAnalysesByDraw$d !== void 0 ? _drawAnalysesByDraw$d : []).map(drawAnalysisId => drawAnalyses[drawAnalysisId]);
  }, [drawAnalyses, drawAnalysesByDraw, drawId]);
};

const getEventSignsForBinaryCoupon = (drawAnalysis, matchAnalyses, eventTypeId, sportType) => {
  const eventSigns = drawAnalysis.gameAnalysesIds.map(analysId => {
    const outcomesToConvertToSigns = [];
    for (const outcomesValue of matchAnalyses[analysId].gamePredictions.prediction[0].outcomes) {
      let outcomes = '';
      for (const outcome of outcomesValue) {
        outcomes += outcome.key;
      }
      outcomesToConvertToSigns.push({
        outcomes,
        eventTypeId,
        sportType
      });
    }
    return convertToSigns(outcomesToConvertToSigns, eventTypeId);
  });
  return eventSigns;
};

const getEventSignsForMatrixCoupon = (drawAnalysis, matchAnalyses, eventTypeId, sportType) => {
  const eventSigns = convertToSigns(drawAnalysis.gameAnalysesIds.map(analysisId => matchAnalyses[analysisId]).map(matchAnalysis => {
    let outcomes = '';
    const srowPredictionIndex = matchAnalysis.gamePredictions.prediction.findIndex(prediction => prediction.system === PREDICTION_SROW);
    const outcomeIndex = srowPredictionIndex === -1 ? 0 : srowPredictionIndex;
    for (const outcome of matchAnalysis.gamePredictions.prediction[outcomeIndex].outcomes[0]) {
      outcomes += outcome.key;
    }
    return {
      outcomes,
      eventTypeId,
      sportType
    };
  }), eventTypeId);
  return eventSigns;
};
const useTransferAnalyses = function (drawId) {
  let eventTypeId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  let sportType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
  const authorProfiles = useSelector(selectAuthorProfiles);
  const drawAnalyses = useDrawAnalyses(drawId);
  const matchAnalyses = useSelector(selectMatchAnalyses);
  const isResultEventType = eventTypeId === EventTypeId.RESULT;
  const transferAnalyses = useMemo(() => drawAnalyses.map(drawAnalysis => ({
    id: drawAnalysis.id,
    authorName: authorProfiles[drawAnalysis.authorId].authorLongName,
    signs: isResultEventType ? getEventSignsForBinaryCoupon(drawAnalysis, matchAnalyses, eventTypeId, sportType) : getEventSignsForMatrixCoupon(drawAnalysis, matchAnalyses, eventTypeId, sportType)
  })), [authorProfiles, drawAnalyses, eventTypeId, isResultEventType, matchAnalyses, sportType]);
  return transferAnalyses;
};
const useAnalyses = function (productId, drawNumber) {
  let eventTypeId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : EventTypeId.EVENT_1X2;
  useFetchAnalysesByDraw(drawNumber, productId);
  const authorProfiles = useSelector(selectAuthorProfiles);
  const drawAnalyses = useDrawAnalyses("".concat(productId, "_").concat(drawNumber));
  const analyses = useMemo(() => drawAnalyses.map(drawAnalysis => _objectSpread(_objectSpread({}, drawAnalysis), {}, {
    authorProfile: authorProfiles[drawAnalysis.authorId]
  })), [authorProfiles, drawAnalyses]);
  const matchAnalyses = useSelector(selectMatchAnalyses);
  const baseRows = useMemo(() => {
    const analysesByAuthorId = {};
    analyses.forEach(drawAnalysis => {
      const baseRow = getEventSignsForMatrixCoupon(drawAnalysis, matchAnalyses, eventTypeId, 1);
      analysesByAuthorId[drawAnalysis.authorId] = baseRow;
    });
    return analysesByAuthorId;
  }, [analyses, matchAnalyses, eventTypeId]);
  return {
    analyses,
    baseRows
  };
};
const useParticipantsNames = matchAnalys => {
  var _matchAnalys$gameInfo;
  const matchId = matchAnalys === null || matchAnalys === void 0 || (_matchAnalys$gameInfo = matchAnalys.gameInfo) === null || _matchAnalys$gameInfo === void 0 ? void 0 : _matchAnalys$gameInfo.sportEvents[0];
  const participantNames = useSelector(state => selectParticipantNamesByMatchId(state, matchId));
  return participantNames;
};
setGlobal('svs.components.sportinfo.gameAnalysesRedux.hooks', {
  useFetchAnalysesByDraw,
  useAuthorProfilePicture,
  useAnalysisOutcomes,
  useDrawAnalyses,
  useTransferAnalyses,
  useAnalyses,
  useParticipantsNames
});

 })(window);