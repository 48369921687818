(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/game-analyses-redux/assets/normalize-analyse.js') >= 0) return;  svs.modules.push('/components/sportinfo/game-analyses-redux/assets/normalize-analyse.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  productIds
} = svs.utils.products;
const predictionTypes = {
  OUTCOMES: 'outcomes',
  BOARD_DATA: 'boardData'
};
const convertFromBoardToOutcome = predictionValues => {
  const convDataArr = [];
  for (const predictionValue of predictionValues) {
    const outcomeData = {
      key: undefined,
      description: '',
      outcomeNumber: ''
    };
    const nrOfGoals = predictionValue[0];
    outcomeData.key = nrOfGoals === 'F' ? 10 : parseInt(nrOfGoals, 10);
    outcomeData.description = nrOfGoals === 'F' ? '10+' : nrOfGoals.toString();
    outcomeData.outcomeNumber = nrOfGoals === 'F' ? '10' : nrOfGoals.toString();
    convDataArr.push(outcomeData);
  }
  return convDataArr;
};
const normalizePredictions = _ref => {
  let {
    gamePredictions,
    productId
  } = _ref;
  const normalizedPredictions = [];
  for (const predictionData of gamePredictions.prediction) {
    const outcomeData = [];
    let system = '';
    if (Object.hasOwnProperty.call(predictionData, predictionTypes.BOARD_DATA) && productId === productIds.BOMBEN) {
      system = predictionData.systemType;
      const boardData = predictionData.boardData;
      for (const prediction of boardData) {
        outcomeData.push(convertFromBoardToOutcome(prediction));
      }
    } else {
      system = predictionData.system;
      outcomeData.push(predictionData.outcomes);
    }
    normalizedPredictions.push({
      outcomes: outcomeData,
      system
    });
  }
  return normalizedPredictions;
};
const normalizeGameAnalyses = gameAnalyses => {
  const normalizedGameAnalyses = [];
  for (const gameAnalys of gameAnalyses) {
    const productId = parseInt(gameAnalys.gameInfo.products[0], 10);
    const normalizedGameAnalys = _objectSpread(_objectSpread({}, gameAnalys), {}, {
      gamePredictions: {
        prediction: normalizePredictions({
          gamePredictions: gameAnalys.gamePredictions,
          productId
        })
      }
    });
    normalizedGameAnalyses.push(normalizedGameAnalys);
  }
  return normalizedGameAnalyses;
};
const normalizeAnalyses = analyses => {
  if (!analyses) {
    return analyses;
  }
  const normalizedAnalyses = [];
  for (const analys of analyses) {
    const normalizedAnalys = _objectSpread(_objectSpread({}, analys), {}, {
      gameAnalyses: normalizeGameAnalyses(analys.gameAnalyses)
    });
    normalizedAnalyses.push(normalizedAnalys);
  }
  return normalizedAnalyses;
};
setGlobal('svs.components.sportinfo.gameAnalysesRedux.normalizeAnalyses', {
  normalizeAnalyses
});

 })(window);