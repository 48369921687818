(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/game-analyses-redux/assets/normalize-draw-data.js') >= 0) return;  svs.modules.push('/components/sportinfo/game-analyses-redux/assets/normalize-draw-data.js');
"use strict";


const defaultHomeName = 'Hemmalag';
const defaultAwayName = 'Bortalag';
const normalizeDrawDataFromAnalyseData = analyseData => {
  if (!analyseData) {
    return analyseData;
  }
  const normalizedDrawData = {};
  for (const drawAnalysis of analyseData) {
    const {
      gameAnalyses
    } = drawAnalysis;
    for (const matchAnalysis of gameAnalyses) {
      var _matchAnalysis$title, _matchAnalysis$gameIn;
      const participantsNames = (matchAnalysis === null || matchAnalysis === void 0 || (_matchAnalysis$title = matchAnalysis.title) === null || _matchAnalysis$title === void 0 ? void 0 : _matchAnalysis$title.split(' - ')) || [defaultHomeName, defaultAwayName];
      const homeTeamName = participantsNames[0];
      const awayTeamName = participantsNames[1];
      normalizedDrawData[(_matchAnalysis$gameIn = matchAnalysis.gameInfo) === null || _matchAnalysis$gameIn === void 0 ? void 0 : _matchAnalysis$gameIn.sportEvents] = {
        homeTeam: {
          name: homeTeamName,
          shortName: homeTeamName
        },
        awayTeam: {
          name: awayTeamName,
          shortName: awayTeamName
        }
      };
    }
  }
  return normalizedDrawData;
};
const normalizeDrawData = drawData => {
  if (!drawData || Object.keys(drawData).length === 0) {
    return drawData;
  }
  const normalizedDrawData = {};
  for (const event of drawData.events) {
    normalizedDrawData[event.match.matchId] = {
      homeTeam: event.match.participants[0],
      awayTeam: event.match.participants[1]
    };
  }
  return normalizedDrawData;
};
setGlobal('svs.components.sportinfo.gameAnalysesRedux.normalizeDrawData', {
  normalizeDrawData,
  normalizeDrawDataFromAnalyseData
});

 })(window);